<template>
  <item
    :config="config"
    :open="open"
    :searchHighlights="searchHighlights"
    :listItem="listItem"
    :narrow="narrow"
    v-on="$listeners"
    class="parliament-resolution-item"
  >
    <div v-if="listItem" class="item__closed-stage">{{ listItem.stage }}</div>
  </item>
</template>

<script>
import Item from '../../components/Item/Item';
import { ParliamentResolutionItemPrepare } from './util/ParliamentResolutionItemPrepare';

export default {
  components: { Item },
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `${window.location.origin}${this.$route.fullPath}`,
        },
      ],
    };
  },
  props: {
    open: Boolean,
    searchHighlights: {
      query: String,
      keywords: Array,
    },
    listItem: Object,
    narrow: Boolean,
  },
  data() {
    return {
      config: {
        routeName: 'parliament-resolutions-item',
        routeList: 'parliament-resolutions',
        route404: 'parliament-resolutions',
        prepareData: ParliamentResolutionItemPrepare,
        api: {
          body: 'parliment_resolution_body',
          highlights: 'parliment_resolutions/load_highlights',
          monitored: 'parliment_resolutions/load_provisions_with_favorite_flag',
          additional: 'parliment_resolution_provisions_and_versions',
          textVersions: {
            lastVersion: 'v1/parliment_resolution_last_version_button',
            version: 'v1/parliment_resolution_version',
          },
          descriptions: 'v1/parliment_resolution_other_descriptions',
        },
        bookmark: {
          type: 'parliament-resolutions',
          fetch: 'fetchParliamentResolutions',
          toggle: 'toggleParliamentResolutions',
        },
        labels: {
          linksTitle: 'Projekt na stronie:',
          linksDoneTitle: 'Uchwała na stronie:',
        },
      },
    };
  },
};
</script>

<style lang="scss">
@import './ParliamentResolutionsItem';
</style>
